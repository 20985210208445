<template>
  <div>
    <TitleSection />

    <v-row>
      <v-col cols="12" xs="12" lg="12" v-for="(announcement, index) in announcenments" :key="index">
        <AnnouncementItem :announcement="announcement">
          <template #default>
            <ProjectActiveFromAnnouncement :announcenment="announcement" />
          </template>
        </AnnouncementItem>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleSection from '@/components/general/TitleSection';
import AnnouncementItem from '@/components/announcenments/AnnouncementItem';
import ProjectActiveFromAnnouncement from '@/components/projects/ProjectActiveFromAnnouncement';

import { ref, onMounted } from '@vue/composition-api'
import api from '@/services';
import { useUtils as useUtilsI18n } from '@core/libs/i18n'

export default {
  components: {
    TitleSection,
    AnnouncementItem,
    ProjectActiveFromAnnouncement
  },
  setup() {
    const { t } = useUtilsI18n();
    const announcenments = ref([]);
    
    const getAnnouncenments = async () => {
      try {
        const { data: response } = await api.getAnnouncenments({
          params: {
            projects: true,
          }
        });
        const { data } = response;
        announcenments.value = data;
        
      } catch (error) {
      }
    };


    onMounted(() => {
      getAnnouncenments();
    })

    return {
      announcenments,

      t
    }
  }
}
</script>

<style>

</style>